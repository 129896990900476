import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay } from "swiper/modules";

import "swiper/css";
import { useRef, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import ModalPop from "./ModalPop";

const Banner = () => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [service, setService] = useState<string>("");
  const [contents, setContents] = useState<string>("");
  const [type, setType] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const handlerPostContact = async () => {
    if (!name || !phone || !address) {
      Swal.fire({
        title: "이름, 연락처, 주소를 모두 입력해주세요.",
        icon: "error",
      });
      return false;
    }

    if (phone.length !== 11 || !phone.includes("010")) {
      alert("010을 포함한 연락처를 모두 입력해주세요.");
      return false;
    }

    if (!window.confirm("문의 접수를 위한 개인정보 수집에 동의하시나요?")) {
      return false;
    }

    setLoading(true);

    await axios
      .post("/api/contact", {
        name: name,
        phone: phone,
        address: address,
        service: service,
        type: type,
        contents: contents,
        path: sessionStorage.getItem("path"),
      })
      .then((res) => {
        if (res.data.result === "0000") {
          Swal.fire({
            title: "문의 완료!",
            text: "문의 등록이 완료되었습니다.",
            icon: "success",
          }).then(() => (window.location.href = "/transform"));
        }
      })
      .catch((e) => {
        setLoading(false);
        Swal.fire({
          title: e,
          icon: "error",
        }).then(() => (window.location.href = "/"));
      });
  };

  const popupRef = useRef<HTMLDivElement>(null);

  const openfunc = () => {
    popupRef.current!.style.display = "block";
  };

  const closefunc = () => {
    popupRef.current!.style.display = "none";
  };

  return (
    <div id="banner" className=" relative w-full h-full">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide onClick={openfunc} className="cursor-pointer">
          <img
            className="hidden xl:block !relative w-full h-[700px] 3xl:h-[760px] min-h-[650px] object-cover object-left"
            src="/images/banner1.png"
            alt="banner1"
            width={2558}
            height={967}
          />
          <img
            className="block xl:hidden !relative w-full h-full object-cover object-center"
            src="/images/banner1_mo.png"
            alt="banner1"
            width={770}
            height={1273}
          />
        </SwiperSlide>
        <SwiperSlide onClick={openfunc} className="cursor-pointer">
          <img
            className="hidden xl:block !relative w-full h-[700px] 3xl:h-[760px] min-h-[650px] object-cover object-left"
            src="/images/banner2.png"
            alt="banner1"
            width={2558}
            height={967}
          />
          <img
            className="block xl:hidden !relative w-full h-full object-cover object-center"
            src="/images/banner2_mo.png"
            alt="banner1"
            width={770}
            height={1273}
          />
        </SwiperSlide>
        <SwiperSlide onClick={openfunc} className="cursor-pointer">
          <img
            className="hidden xl:block !relative w-full h-[700px] 3xl:h-[760px] min-h-[650px] object-cover object-left"
            src="/images/banner3.png"
            alt="banner1"
            width={2558}
            height={967}
          />
          <img
            className="block xl:hidden !relative w-full h-full object-cover object-center"
            src="/images/banner3_mo.png"
            alt="banner1"
            width={770}
            height={1273}
          />
        </SwiperSlide>
        <div className="block sm:block lg:hidden 2xl:block relative 2xl:absolute right-0 2xl:right-[1.5vw] 3xl:right-[calc((100%-var(--container))/2)] top-0 w-full 2xl:w-1/2 3xl:w-1/3 h-full 2xl:max-w-[650px] 3xl:max-w-screen-md bg-gradient-to-r to-[#005bea] from-[#022d90] rounded-bl-3xl z-30">
          <div className="text-center text-white py-3 xl:pt-5">
            <h3 className="GmarketSansMedium !font-normal text-3xl xl:text-4xl">
              누수방수야 “
              <span className="GmarketSans !font-black">상세 견적문의</span>”
            </h3>
          </div>
          <div className="relative w-full lg:min-h-[600px] 3xl:min-h-[680px] rounded-tl-3xl rounded-bl-3xl bg-white px-3 lg:px-5 py-5 lg:pb-0">
            <div className=" relative w-full h-full grid grid-cols-3 gap-3">
              <div className=" relative w-full h-full">
                <label htmlFor="name" className="lg:text-xl">
                  이름&nbsp;<span className="text-[var(--red-color)]">*</span>
                </label>
                <input
                  type="text"
                  placeholder="이름을 입력해주세요"
                  value={name}
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className=" relative w-full h-full">
                <label htmlFor="phone" className="lg:text-xl">
                  연락처&nbsp;<span className="text-[var(--red-color)]">*</span>
                </label>
                <input
                  type="text"
                  placeholder="연락처를 입력해주세요"
                  value={phone}
                  id="phone"
                  onChange={(e) =>
                    setPhone(
                      e.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*)\./g, "$1")
                    )
                  }
                />
              </div>
              <div className=" relative w-full h-full">
                <label htmlFor="type1" className="lg:text-xl">
                  유형
                </label>
                <select
                  name="type1"
                  id="type1"
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="">긴급 or 일반</option>
                  <option value="긴급">긴급</option>
                  <option value="일반">일반</option>
                </select>
              </div>
              <div className="col-span-2 relative w-full h-full">
                <label htmlFor="address" className="lg:text-xl">
                  주소&nbsp;<span className="text-[var(--red-color)]">*</span>
                </label>
                <input
                  type="text"
                  placeholder="주소를 입력해주세요"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className=" relative w-full h-full">
                <label htmlFor="service1" className="lg:text-xl">
                  문의종류
                </label>
                <select
                  name="service1"
                  id="service1"
                  onChange={(e) => setService(e.target.value)}
                >
                  <option value="">서비스 목록</option>
                  <option value="누수탐지">누수탐지</option>
                  <option value="누수공사">누수공사</option>
                  <option value="화장실누수">화장실누수</option>
                  <option value="방수공사">방수공사</option>
                  <option value="배관누수">배관누수</option>
                  <option value="아파트누수">아파트누수ㅇ</option>
                </select>
              </div>
              <div className="col-span-3 row-span-2 relative w-full h-full">
                <label htmlFor="contents" className="lg:text-xl">
                  문의 내용
                </label>
                <textarea
                  placeholder="내용을 입력해주세요"
                  value={contents}
                  name="contents"
                  id="contents"
                  onChange={(e) => setContents(e.target.value)}
                ></textarea>
                <div className="relative w-full h-fit flex justify-between items-center">
                  <p className="leading-tight">
                    문자&nbsp;접수&nbsp;후&nbsp;
                    <span className="text-[var(--red-color)]">
                      20분&nbsp;이내에
                    </span>
                    <br />
                    직접&nbsp;전화드릴&nbsp;예정입니다.
                  </p>
                  <button
                    type="button"
                    onClick={handlerPostContact}
                    disabled={loading}
                    className=" relative w-fit h-fit py-2 px-5 text-white bg-gradient-to-r to-[#005bea] from-[#022d90]"
                  >
                    <h3 className="manru text-shadow">전송하기</h3>
                  </button>
                </div>
              </div>

              <div className="col-span-3 relative w-full h-fit cursor-pointer">
                <img
                  onClick={() => {
                    // @ts-ignore
                    AM_PL("https://xn--od1bk6qg0eba454b.com/tel");
                    window.location.href = "tel:010-8330-8913";
                  }}
                  className="!relative w-full h-full object-contain"
                  src="/images/banner_num.png"
                  alt="number"
                  width={858}
                  height={169}
                />
              </div>
            </div>
          </div>
        </div>
      </Swiper>
      <ModalPop popupRef={popupRef} closefnc={closefunc} />
    </div>
  );
};

export default Banner;
